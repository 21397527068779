<script lang="ts">
	import { type MenuItem, SpecialMenuItem } from "../../../../core/schema/MenuItem.js";
	import Link from "../Link.svelte";
	import StaticPicture from "../Picture/StaticPicture.svelte";
	import DynamicPicture from "../Picture/DynamicPicture.svelte";
	import fb from "../../../../core/assets/icons/fb.svg?raw";
	import linkedin from "../../../../core/assets/icons/linkedin.svg?raw";
	import ig from "../../../../core/assets/icons/ig.svg?raw";

	export let submenu: MenuItem["submenus"][number];
	export let submenuIndex: number;
</script>

<div class="xl:text-2md flex flex-col gap-5 text-base lg:flex">
	{#if Array.isArray(submenu)}
		{#each submenu as menu, menuIndex}
			<Link
				noUnderline
				containerClass="w-full relative "
				class="hover:!text-primary !text-black {menu.showOnMobile === false ? 'hidden lg:block' : ''}"
				href={menu.path}
			>
				{#if menu.image}
					{#if menuIndex < submenu.length}
						<div
							class="bg-tertiary absolute left-1/2 top-0 h-[2px] w-10/12 -translate-x-1/2"
							class:max-lg:hidden={submenuIndex === 0 && menuIndex === 0}
						></div>
					{/if}
				{/if}
				<div class="flex items-center gap-5" class:mt-4={menu.image}>
					{#if menu.image}
						{#if typeof menu.image === "string"}
							<StaticPicture image={menu.image} width={128} height={128} alt={menu.name} />
						{:else}
							<DynamicPicture
								image={menu.image}
								class="relative aspect-square h-16"
								width={86}
								height={86}
								alt={menu.image.title ?? menu.name}
								imgClass="object-contain !w-full !h-full absolute inset-0"
								bucket="storage"
							/>
						{/if}
					{/if}
					<span>{menu.name}</span>
				</div>
			</Link>
		{/each}
	{:else if submenu === SpecialMenuItem.Socials}
		<div class="hidden flex-wrap gap-28 pt-8 lg:flex">
			<Link
				aria-label="Facebook"
				href="https://www.facebook.com/groupbrano/?paipv=0&eav=AfYRcYA1-GsqsNVNIdevf2-kuPWkwW8L9ddB5GJmg1eHHY0bh6_Izl98Qu0wAeSR8Bs&_rdr"
				variant="transparent"
				icon={fb}
				iconClass="h-[1.75rem] text-[#AEAEB2]"
			/>
			<Link
				aria-label="LinkedIn"
				href="https://cz.linkedin.com/company/brano-a.s."
				variant="transparent"
				icon={linkedin}
				iconClass="h-[1.75rem] text-[#AEAEB2]"
			/>
			<Link
				aria-label="Instagram"
				href="https://www.instagram.com/brano.group"
				variant="transparent"
				icon={ig}
				iconClass="h-[1.75rem] text-[#AEAEB2]"
			/>
		</div>
	{/if}
</div>
