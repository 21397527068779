import sha1 from "simple-sha1";
import type { Media } from "../schema/Media.js";

export function thumb(
	bucketUrl: string,
	width: number,
	height: number,
	image: Media | string,
	format: ".webp" | ".avif" | null = ".webp",
): string {
	const webpPath = `${typeof image === "string" ? image : image.path}${format ?? ""}`;
	const url = new URL(webpPath, "https://example.com");
	url.pathname = `/${width}x${height}${url.pathname}`;
	return `${bucketUrl}${url.pathname}?hash=${sha1.sync(url.pathname.replace("/", ""))}`;
}
