import type { Locale, LocalizedString } from "../../../core/schema/Locale.js";
import { nbspify } from "../../../core/utils/nbspify.js";

export function translate<Translations extends Record<string, LocalizedString>, Key extends keyof Translations>(
	translations: Translations,
	key: Key,
	locale: Locale,
	variables: Record<string, string | number> = {},
	disableNbspify = true,
): Translations[Key][Locale] {
	const translation = translations[key]?.[locale];
	let finalTranslation = String(translation ?? key);
	for (const [variable, value] of Object.entries(variables)) {
		finalTranslation = finalTranslation.replaceAll(`{${variable}}`, String(value));
	}
	const text = finalTranslation as Translations[Key][Locale];
	if (disableNbspify) {
		return text;
	}
	return nbspify(text);
}
