import type { Locale, LocalizedString } from "../../../core/schema/Locale.js";
import { getTenant } from "../contexts/tenantContextKey.js";
import { translate } from "./translate.js";

export function getTranslate<Translations extends Record<string, LocalizedString>>(
	translations: Translations,
	disableNbspify = false,
	locale = getTenant().locale,
) {
	return <Key extends keyof Translations>(
		key: Key,
		variables: Record<string, string | number> = {},
		_disableNbspify = disableNbspify,
	): Translations[Key][Locale] => {
		return translate<Translations, Key>(translations, key, locale, variables, _disableNbspify);
	};
}
